import dynamic from 'next/dynamic';

export { default as SectionHeader } from './SectionHeader';
export { default as TypedText } from './TypedText';
export { default as LoadingOverlay } from './LoadingOverlay';

export const CountUpNumber = dynamic(() => import('./CountUpNumber'));
export const DescriptionCta = dynamic(() => import('./DescriptionCta'));
export const IconAlternate = dynamic(() => import('./IconAlternate'));
export const OverlapedImages = dynamic(() => import('./OverlapedImages'));
export const SwiperImage = dynamic(() => import('./SwiperImage'));
export const SwiperNumber = dynamic(() => import('./SwiperNumber'));
