import {
  useMediaQuery,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  colors,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

import { IconAlternate, SectionHeader } from '@/src/components/molecules';

const items = [
  {
    id: 'item-1',
    title: 'Mensalidades',
    icon: 'fas fa-calendar-check',
  },
  {
    id: 'item-2',
    title: 'Material didático',
    icon: 'fas fa-pencil-ruler',
  },
  {
    id: 'item-3',
    title: 'Lista de livros',
    icon: 'fas fa-book',
  },
  {
    id: 'item-4',
    title: 'Cursos extracurriculares',
    icon: 'fas fa-swimmer',
  },
  {
    id: 'item-5',
    title: 'Saídas pedagógicas',
    icon: 'fas fa-bus-alt',
  },
  {
    id: 'item-6',
    title: 'Matrícula e rematrícula',
    icon: 'fas fa-file-signature',
  },
  {
    id: 'item-7',
    title: 'Uniformes',
    icon: 'fas fa-tshirt',
  },
  {
    id: 'item-8',
    title: 'Lista de materiais escolares',
    icon: 'fas fa-clipboard-list',
  },
];

const Sellers = props => {
  const { className, ...rest } = props;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <SectionHeader
            title="O que nossos clientes vendem?"
            subtitle="A criatividade é o limite. Você ainda pode criar pacotes de produtos ou fazer recomendações, aproveitando as oportunidades para aumentar suas vendas. Veja alguns exemplos:"
            align="left"
            disableGutter
            data-aos="fade-up"
          />
          <Grid container spacing={isMd ? 1 : 0.5} mt={2}>
            {items.map(item => (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <ListItem data-aos="fade-up">
                  <ListItemAvatar sx={{ marginRight: 2 }}>
                    <IconAlternate
                      size="medium"
                      fontIconClass={item.icon}
                      color={colors.lightBlue}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.title}
                    primaryTypographyProps={{ variant: 'h6', component: 'div' }}
                  />
                </ListItem>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Sellers.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Sellers;
